import { Template1, Template10, Template11, Template12, Template13, Template14, Template15, Template16, Template17, Template2,Template3, Template4, Template5, Template6, Template7, Template8, Template9,Template18 } from "../components/designs";


export const adminIds =["101953298823536952240","107042114843270501682"]

export const initialTags = [
  "Software Engineer",
  "Front-end Developer",
  "Back-end Developer",
  "Full-stack Developer",
  "Web Developer",
  "UI/UX Designer",
  "Graphic Designer",
  "Data Scientist",
  "Product Manager",
  "Project Manager",
  "Business Analyst",
  "Marketing Manager",
  "Sales Representative",
  "Customer Service Representative",
  "HR Manager",
  "Financial Analyst",
  "Content Writer",
  "Teacher/Educator",
  "Healthcare Professional",
  "Boys",
  "Girls",
  "Simple",
  "Biologist"
];

export const FiltersData = [
  { id: "1", label: "Software Engineer", value: "Software Engineer" },
  { id: "2", label: "Front-end Developer", value: "Front-end Developer" },
  { id: "3", label: "Back-end Developer", value: "Back-end Developer" },
  { id: "4", label: "Full-stack Developer", value: "Full-stack Developer" },
  { id: "5", label: "Web Developer", value: "Web Developer" },
  { id: "6", label: "UI/UX Designer", value: "UI/UX Designer" },
  { id: "7", label: "Graphic Designer", value: "Graphic Designer" },
  { id: "8", label: "Data Scientist", value: "Data Scientist" },
  { id: "9", label: "Product Manager", value: "Product Manager" },
  { id: "10", label: "Project Manager", value: "Project Manager" },
  { id: "11", label: "Business Analyst", value: "Business Analyst" },
  { id: "12", label: "Marketing Manager", value: "Marketing Manager" },
  { id: "13", label: "Sales Representative", value: "Sales Representative" },
  {
    id: "14",
    label: "Customer Service Representative",
    value: "customer_service_representative",
  },
  { id: "15", label: "HR Manager", value: "hr manager" },
  { id: "16", label: "Financial Analyst", value: "financial_analyst" },
  { id: "17", label: "Content Writer", value: "content writer" },
  { id: "18", label: "Teacher/Educator", value: "teacher educator" },
  {
    id: "19",
    label: "Healthcare Professional",
    value: "healthcare_professional",
  },
  { id: "20", label: "Boys", value: "boys" },
  { id: "21", label: "Girls", value: "girls" },
  { id: "22", label: "Simple", value: "Simple" },
  { id: "22", label: "Biologist", value: "biologist" },
  
];

export const TemplatesData = [
  
  { id: `template-${Date.now()}`, name: "Template1", component: Template1 },
  { id: `template-${Date.now()}`, name: "Template2", component: Template2 },
  { id: `template-${Date.now()}`, name: "Template3", component: Template3 },
  { id: `template-${Date.now()}`, name: "Template4", component: Template4 },
  { id: `template-${Date.now()}`, name: "Template5", component: Template5 },
  { id: `template-${Date.now()}`, name: "Template6", component: Template6 },
  { id: `template-${Date.now()}`, name: "Template7", component: Template7 },
  { id: `template-${Date.now()}`, name: "Template8", component: Template8 },
  { id: `template-${Date.now()}`, name: "Template9", component: Template9 },
  { id: `template-${Date.now()}`, name: "Template10", component: Template10 },
  { id: `template-${Date.now()}`, name: "Template11", component: Template11 },
  { id: `template-${Date.now()}`, name: "Template12", component: Template12 },
  { id: `template-${Date.now()}`, name: "Template13", component: Template13 },
  { id: `template-${Date.now()}`, name: "Template14", component: Template14 },
  { id: `template-${Date.now()}`, name: "Template15", component: Template15 },
  { id: `template-${Date.now()}`, name: "Template16", component: Template16 },
  { id: `template-${Date.now()}`, name: "Template17", component: Template17 },
  { id: `template-${Date.now()}`, name: "Template18", component: Template18 },

  
  
];