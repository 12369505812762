import React, { useState } from 'react'
import useUser from '../hooks/useUser'
import { Link } from 'react-router-dom'
import { Logo } from '../assets'
import { PuffLoader } from 'react-spinners'
import { AnimatePresence,motion } from 'framer-motion'
import {HiLogout} from "react-icons/hi"
import { FadeInOutWithOpacity, slideUpDownMenu } from '../animations'
import { useQueryClient } from 'react-query'
import { auth } from '../config/firebase.config'
import { adminIds } from '../utils/helpers'
import useFilters from '../hooks/useFilters'

function Header() {
    const {data, isLoading, isError} =useUser()
    const [isMenu, setisMenu] = useState(false)
    const { data:filterData } =useFilters()

   

const queryClient =useQueryClient()
const signOutUser = async() =>{
  await auth.signOut().then(()=> {
    queryClient.setQueryData("user", null)
  })
} 

const handleSearchTerm = (e) =>{
  queryClient.setQueryData("globalFilter",
     {...queryClient.getQueryData("globalFilter"),
    searchTerm :e.target.value})
}


const clearFilter =()=>{
  queryClient.setQueryData("globalFilter",
     {...queryClient.getQueryData("globalFilter"),
      searchTerm :""})

}

  return (
    <header className="w-full bg-slate-300 flex items-center justify-between px-4 py-3 lg:px-8
     border-b border-gray-300  z-40 gap-12 sticky top-0" >
<Link to={"/"}>
<img src={Logo} className='w-12 h-auto object-contain' alt='' />
</Link>

<div className='flex-1 border border-gray-300 px-4 py-1 rounded-md
 flex items-center justify-between bg-gray-200'> 
<input type='text'
value={filterData?.searchTerm? filterData?.searchTerm :""}
 placeholder='search here .....'
  className='flex-1 h-10 bg-transparent 
text-base font-semibold outline-none border-none'
onChange={handleSearchTerm}

/>

<AnimatePresence>
  {filterData?.searchTerm.length > 0 && (
    <motion.div {...FadeInOutWithOpacity}
     className='w-8 h-8 flex items-center justify-center bg-gray-300 rounded-md
    cursor-pointer active:scale-95 duration-150' 
    onClick={clearFilter}>
     <p className='text-2xl text-black'>x</p>
   </motion.div>
  )}
</AnimatePresence>


</div>
<AnimatePresence>

  {isLoading? (
    <PuffLoader color='#498FCD' size={40}/>
    ):(
    <React.Fragment>
    
    {data? (
      <motion.div  {...FadeInOutWithOpacity} className='relative' onClick={()=>setisMenu(!isMenu)}>
      {data?.photoURL? (
        <div className='w-12 h-12 rounded-md relative flex items-center cursor-pointer
      justify-center '>
<img src={data?.photoURL}
 className='w-full h-full object-cover rounded-md'
 alt=''
 referrerPolicy='no-referrer'
 />
      </div>
      ):(
        <div className='w-12 h-12 rounded-md relative flex
         items-center justify-center cursor-pointer
          bg-blue-700 shadow-md'>
            <p className='text-lg text-white'>{data?.displayName[0]} </p>
          </div>
          )}

          {/* dropdown menu */}
         <AnimatePresence>
         {isMenu && <motion.div
        {...slideUpDownMenu}
         className='absolute px-4 py-3 rounded-md bg-gray-100 right-0 top-16 
          flex flex-col items-center justify-start gap-3 w-64 pt-12' onMouseLeave={()=>setisMenu(false)}>
  {data?.photoURL? (
        <div className='w-20 h-20 rounded-md relative flex flex-col items-center cursor-pointer
      justify-center '>
<img src={data?.photoURL}
 className='w-full h-full object-cover rounded-full'
 alt=''
 referrerPolicy='no-referrer'
 />
 
      </div>
      ):(
        <div className='w-20 h-20 rounded-full relative flex
         items-center justify-center cursor-pointer
          bg-blue-700 shadow-md'>
            <p className='text-3xl text-white'>{data?.displayName[0]} </p>
          </div>
          )}
{data?.displayName && (
   <p className='text-lg text-txDark'>
    {data?.displayName}
     </p>
 )}

{/*  menu options */}
 <div className='w-full flex-col items-start flex gap-8 pt-6'>
  <Link className='text-txtLight hover:to-txtDark
   text-base whitespace-nowrap' to={`/profile/${data?.uid}`}>My account</Link>

  {
    adminIds.includes(data?.uid) && (
      <Link className='text-txtLight hover:to-txtDark
   text-base whitespace-nowrap' to={"/template/create"}>
    Add new template
    </Link>
    )
  }
<div className='w-full px-2 py-2 border-t border-gray-300
 flex items-center justify-between groupe cursor-pointer' onClick={signOutUser}>

  <p className=' group-hover:text- text-txtLight'>Sign out</p>
<HiLogout className=' group-hover:text-txtDark text-txtLight' />
 </div>
 </div>
          </motion.div>}
         </AnimatePresence>
    </motion.div>
    ):(
    <Link to={"/auth"} >
    
    <motion.button className='px-4 py-2 rounded-md border
     border-gray-300 bg-gray-200 
     hover:shadow-md active:scale-95 duration-150' type='button' {...FadeInOutWithOpacity} >
      login
      
      </motion.button>
    </Link>)}
    </React.Fragment>
    )}
</AnimatePresence>

    </header>
    )
  
}

export default Header