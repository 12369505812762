import React from 'react';
import { useNavigate } from 'react-router-dom';

const Contact = () => {
  const navigate = useNavigate(); // Hook for navigation

  // Function to go back to the previous page
  const handleBackClick = () => {
    navigate('/auth'); // This will navigate back to the previous page in the history stack
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h1 className="text-3xl font-semibold text-center mb-6">Contact Us</h1>

        {/* Contact Info Section */}
        <div className="space-y-4 mb-6">
          <p>
            <strong className="mr-2">Email:</strong> cvdzbuilder@gmail.com
          </p>
          <p>
            <strong>Address:</strong> Constantine, Algeria
          </p>
        </div>

        {/* Back Button */}
        <button
          onClick={handleBackClick}
          className="w-full mt-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700"
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default Contact;
