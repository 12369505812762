import React, { useEffect } from 'react';
import { Logo } from "../assets";
import Footer from "../containers/Footer";
import { AuthButtonWithProvider } from '../components';
import { FaGoogle, FaGithub } from "react-icons/fa6";
import useUser from '../hooks/useUser';
import { useNavigate } from 'react-router-dom';
import MainSpinner from '../components/MainSpinner';
import { onAuthStateChanged } from "firebase/auth"; // Import the listener
import { auth } from "../config/firebase.config"; // Import the Firebase auth instance

function Authentification() {
  const { data, isLoading, isError } = useUser();
  const navigate = useNavigate();

  const images = [
    "https://iili.io/20HZrue.png",
    "https://iili.io/20HZD8P.jpg",
    "https://iili.io/20HZ4wu.png",
    "https://iili.io/20HZ6Zb.png",
   "https://iili.io/20Ht9ta.png",
   "https://iili.io/20HtJoJ.png",
    "https://iili.io/20HtdMv.png"
  ];

  // This useEffect ensures that the user is redirected to the homepage if logged in
  useEffect(() => {
    // This listens for the authentication state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/", { replace: true }); // Redirect to the home page if user is logged in
      }
    });

    // Cleanup the listener when component unmounts or changes
    return () => unsubscribe();
  }, [navigate]);

  // If the user is still loading or there is an error, display the spinner or handle error.
  if (isLoading) {
    return <MainSpinner />;
  }

  return (
    <div className='auth-section overflow-scroll'>
 <img src={Logo} className='w-12 h-auto object-contain' alt='' />

     

      <div className='w-full flex flex-1 flex-col items-center justify-center gap-4'>
     
        <h1 className='text-3xl lg:text-4xl text-blue-700'>Welcome to ResumeDz</h1>
        <p className='text-base text-gray-600 capitalize'>easy way to create resume</p>
        <h2 className='text-2xl text-gray-600'>Authenticate</h2>
        <div className='w-full lg:w-96 rounded-md p-2 flex flex-col items-center justify-start gap-6'>
          <AuthButtonWithProvider
            Icon={FaGoogle}
            label={"Sign in with Google"}
            provider={"GoogleAuthProvider"} />

       
        </div>
        <div className="p-4 border  rounded-md flex flex-col text-center items-center justify-center">
      <h1 className="text-2xl font-bold mb-4 capitalize">daily new templates
      </h1>
      <div className="grid grid-cols-2 mx-auto sm:grid-cols-3 lg:grid-cols-4 gap-4">
        {images.map((src, index) => (
          <div key={index} className="rounded-lg border p-2 bg-gray-100 overflow-hidden shadow-lg">
            <img src={src} alt={`Image ${index}`} className="w-52 h-52 object-contain" />
          </div>
        ))}
      </div>
    </div>
      </div>

      <Footer />
    </div>
  );
}

export default Authentification;
