import React, { useState } from 'react';
import imageCompression from 'browser-image-compression';

const ImageResizer = () => {
    const [file, setFile] = useState(null);
    const [compressedFile, setCompressedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleCompression = async () => {
        if (!file) return;

        setLoading(true);
        setProgress(0);

        const options = {
            maxSizeMB: 0.5, // Set max size to 500 KB
            maxWidthOrHeight: 800, // Profile picture resolution
            initialQuality: 0.7, // Higher initial quality
            useWebWorker: true,
            onProgress: (p) => setProgress(p), // Update progress
        };

        try {
            const compressedFile = await imageCompression(file, options);
            setCompressedFile(compressedFile);
            setLoading(false);
            setProgress(100);

            // Auto-download the compressed image
            const url = URL.createObjectURL(compressedFile);
            const a = document.createElement('a');
            a.href = url;
            a.download = `compressed_${file.name}`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    return (
        <div className='w-full px-6 lg:px-16 py-10 flex flex-col items-center justify-start min-h-screen bg-gradient-to-r from-blue-100 via-indigo-100 to-purple-100'>
        <div className="flex flex-col items-center mt-4 p-6 bg-gray-50 rounded-lg shadow-lg max-w-md mx-auto">
            <h1 className="text-2xl font-bold text-gray-800 mb-4">Image Resizer</h1>
            <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="mb-4 border border-gray-300 rounded-md p-2 w-full"
            />
            <button
                onClick={handleCompression}
                disabled={loading}
                className={`w-full py-2 rounded-md text-white transition duration-300 
                    ${loading ? 'bg-blue-300' : 'bg-blue-600 hover:bg-blue-700'}`}
            >
                {loading ? 'Compressing...' : 'Compress Image'}
            </button>

            {loading && (
                <div className="w-full mt-4">
                    <p className="text-gray-600">Loading... {progress.toFixed(0)}%</p>
                    <div className="w-full bg-gray-200 rounded-full mt-1">
                        <div
                            className="bg-blue-600 h-2 rounded-full transition-all duration-300"
                            style={{ width: `${progress}%` }}
                        />
                    </div>
                </div>
            )}

            {compressedFile && !loading && (
                <div className="mt-4 w-full border border-blue-600 rounded-lg overflow-hidden">
                    <h3 className="text-lg font-semibold p-1 bg-green-200 text-gray-700">finished... you can use it now without problem </h3>
                    <img
                        src={URL.createObjectURL(compressedFile)}
                        alt="Compressed"
                        className="max-w-full h-auto"
                    />
                </div>
            )}
        </div>
        </div>
    );
};

export default ImageResizer;
