import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { getTemplateDetails } from '../api';
import { MainSpinner, TemplateDesignPin } from '../components';
import { FaHouse } from 'react-icons/fa6';
import { BiFolderPlus, BiHeart, BiSolidFolderPlus, BiSolidHeart } from 'react-icons/bi';
import useUser from '../hooks/useUser';
import { saveToCollections, saveToFavorites } from '../api';
import useTemplates from '../hooks/useTemplates';
import { AnimatePresence } from 'framer-motion';
import { RiArrowGoBackFill } from "react-icons/ri";


function TemplateDesignPinDetail() {
  const { templateID } = useParams();
  const navigate = useNavigate();
  
  const { data, isError, isLoading, refetch } = useQuery(
    ['template', templateID],
    () => getTemplateDetails(templateID)
  );

  const { data: user, refetch: userRefetch } = useUser();
  const { data: templates, refetch: temp_refetch, isLoading: temp_isLoading } = useTemplates();

  const addToCollection = async (e) => {
    e.stopPropagation();
    if (data.isLocked && !data.isunlocked.includes(user?.uid)) return; // Prevent action if locked
    await saveToCollections(user, data);
    userRefetch();
  };

  const addToFavorites = async (e) => {
    e.stopPropagation();
    if (data.isLocked && !data.isunlocked.includes(user?.uid)) {
      alert("This template is locked. Please purchase to gain access."); // Prevent action if locked
      return;
    }
    await saveToFavorites(user, data);
    temp_refetch();
    refetch();
  };

  useEffect(() => {
    
    window.scrollTo(0, 0);
    if (data && data.isLocked && !data.isunlocked.includes(user?.uid)) {
      alert("This template is locked. Please purchase to gain access.");
      navigate('/'); // Redirect to home or another page
    }
  }, [data, user, navigate]);

  if (isLoading) return <MainSpinner />;

  if (isError) return (
    <div className='w-full h-[60vh] flex flex-col items-center justify-center'>
      <p className='text-lg text-txtPrimary font-semibold'> Something went wrong, try later</p>
    </div>
  );

  return (
    <div className='w-full flex justify-start items-center flex-col px-8 py-6 bg-gradient-to-r from-blue-100 via-indigo-100 to-purple-100'>
      {/* Breadcrumb */}
      <div className='w-full flex items-center pb-8 gap-2'>
        <Link to={"/"} className='flex items-center justify-center gap-2 text-txtPrimary'>
          <FaHouse /> HOME
        </Link>
        <p>/</p>
        <p>{data?.name}</p>
       
      </div>
      <Link to={"/"} className='flex flex-col items-center justify-center gap-2 text-txtPrimary'>
        <RiArrowGoBackFill className='text-5xl -mt-12 font-bold text-blue-600 mx-auto text-center'/><h2 className='font-bold text-3xl capitalize'>back home </h2> 
        
        </Link>
      {/* Design main section layout */}
      <div className='w-full grid grid-cols-1 lg:grid-cols-12'>
        {/* Left section */}
        <div className='col-span-1 lg:col-span-8 flex flex-col items-start justify-start gap-4'>
          {/* Load the template image */}
          <img src={data?.imageURL} alt='' className='w-full h-auto bg-indigo-200 p-8 object-contain rounded-md' />

          {/* Title and other options */}
          <div className='w-full flex flex-col items-start justify-start gap-2'>
            {/* Title section */}
            <div className='w-full flex items-center justify-between'>
              <p className='text-base text-txtPrimary font-semibold'>{data?.title}</p>

              {/* Likes */}
              {data?.favorites?.length > 0 && (
                <div className='flex items-center justify-center gap-1'>
                  <BiSolidHeart className='text-base text-red-500' />
                  <p className='text-base text-txtPrimary font-semibold'>{data?.favorites?.length} likes</p>
                </div>
              )}
            </div>

            {/* Collections and favorite options */}
            {user && (
              <div className='flex items-center justify-center gap-3 my-2'>
                {user?.collections?.includes(data?._id) ? (
                  <div onClick={addToCollection} className='flex items-center justify-center px-4 py-2 rounded-md border border-gray-300 gap-2 hover:bg-gray-200 cursor-pointer'>
                    <BiSolidFolderPlus className="text-base text-txtPrimary" />
                    <p className='text-sm text-txtPrimary whitespace-nowrap'>Remove from collections</p>
                  </div>
                ) : (
                  <div onClick={addToCollection} className='flex items-center justify-center px-4 py-2 rounded-md border border-gray-300 gap-2 hover:bg-gray-200 cursor-pointer'>
                    <BiFolderPlus className="text-base text-txtPrimary" />
                    <p className='text-sm text-txtPrimary whitespace-nowrap'>Add to collections</p>
                  </div>
                )}

                {data?.favorites?.includes(user?.uid) ? (
                  <div onClick={addToFavorites} className='flex items-center justify-center  px-4 py-2 rounded-md border border-gray-300 gap-2 hover:bg-gray-200 cursor-pointer'>
                    <BiSolidHeart className="text-base text-txtPrimary" />
                    <p className='text-sm text-txtPrimary whitespace-nowrap'>You liked this template</p>
                  </div>
                ) : (
                  <div onClick={addToFavorites} className='flex items-center justify-center px-4 py-2 rounded-md border border-gray-300 gap-2 hover:bg-gray-200 cursor-pointer'>
                    <BiHeart className="text-base text-txtPrimary" />
                    <p className='text-sm text-txtPrimary whitespace-nowrap'>Like this template</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Right section */}
        <div className='col-span-1 lg:col-span-4 w-full flex flex-col items-center justify-start px-3 gap-6'>
          {/* Discover more option */}
          <div className='w-full h-72 bg-blue-200 rounded-md overflow-hidden relative' style={{
            background: "url(https://img.freepik.com/photos-gratuite/vue-avocat-exercice-3d_23-2151023412.jpg?t=st=1727280889~exp=1727284489~hmac=f911f7538a2b6b9fb57709cdee607f8db3788513e4669c182f4d2f608bcfebe0&w=826)",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}>
            <div className='absolute inset-0 flex items-center justify-center bg-[rgba(0,0,0,0.4)] '>
              <Link to={"/"} className='px-4 py-2 rounded-md border-2 border-gray-50 text-white'>Discover More</Link>
            </div>
          </div>

          {/* Edit the template */}
          {user && (
            <Link className='w-full px-4 py-3 rounded-md flex items-center justify-center bg-emerald-500 cursor-pointer' to={`/resume/${data?.name}?templateId=${templateID}`}>
              <p className='text-white font-semibold text-lg'>Edit this Template</p>
            </Link>
          )}

          {/* Tags */}
          <div className='w-full flex items-center justify-start flex-wrap gap-2'>
            {data?.tags?.map((tag, index) => (
              <p className='text-xs border border-gray-300 px-2 py-1 rounded-md whitespace-nowrap' key={index}>
                {tag}
              </p>
            ))}
          </div>
        </div>
      </div>

      {/* Similar templates */}
      {templates?.filter((temp) => temp._id !== data?._id).length > 0 && (
        <div className='w-full py-8 flex flex-col items-start justify-start gap-4'>
          <p className='capitalize text-lg font-semibold text-txtDark'>You might also like</p>

          <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-2'>
            <React.Fragment>
              <AnimatePresence>
                {templates.filter((temp) => temp._id !== data?._id).map((template, index) => (
                  <TemplateDesignPin
                    key={template?._id}
                    data={template}
                    index={index}
                  />
                ))}
              </AnimatePresence>
            </React.Fragment>
          </div>
        </div>
      )}
    </div>
  );
}

export default TemplateDesignPinDetail;
