import React, { useEffect, useState } from 'react';
import { FaTrash, FaUpload } from 'react-icons/fa';
import { PuffLoader } from 'react-spinners';
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { db, storage } from '../config/firebase.config';
import { toast } from 'react-toastify';
import { adminIds, initialTags } from '../utils/helpers';
import { deleteDoc, doc, serverTimestamp, setDoc } from 'firebase/firestore';
import useTemplates from '../hooks/useTemplates';
import { useNavigate } from 'react-router-dom';
import useUser from '../hooks/useUser';


const CreateTemplate = () => {
  const [formData, setFormData] = useState({
    title: "",
    imageURL: null,
  });

  const [ImageAsset, setImageAsset] = useState({
    isImageLoading: false,
    uri: null,
    progress: 0,
  });

  const [selectedTags, setselectedTags] = useState([])

  const {data :templates,
     isLoading:templatesIsLoading, 
    isError:templatesIsError,
     refetch:templatesRefetch} = useTemplates()

     const {data :user, isLoading}= useUser()

     const navigate =useNavigate()

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevRec) => ({ ...prevRec, [name]: value }));
  };

  const handleFileSelect = async (e) => {
    setImageAsset((prevAsset) => ({ ...prevAsset, isImageLoading: true }));
    const file = e.target.files[0];

    if (file && isAllowed(file)) {
      const storageRef = ref(storage, `Templates/${Date.now()}-${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on('state_changed', (snapshot) => {
        setImageAsset((prevAsset) => ({
          ...prevAsset,
          progress: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        }));
      }, (error) => {
        if (error.message.includes("storage/unauthorized")) {
          toast.error(`Error: Authorization Revoked`);
        } else {
          toast.error(`Error: ${error.message}`);
        }
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImageAsset((prevAsset) => ({ ...prevAsset, uri: downloadURL }));
        });
        toast.success("Image uploaded");
        setInterval(() => {
          setImageAsset((prevAsset) => ({ ...prevAsset, isImageLoading: false }));
        }, 2000);
      });
    } else {
      toast.info("Invalid file format");
    }
  };

  const isAllowed = (file) => {
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    return allowedTypes.includes(file.type);
  };

  const handleSelectedTags =(tag)=>{
if(selectedTags.includes(tag)){
  //if selected then remove it
  setselectedTags(selectedTags.filter(selected=> selected !== tag))
}else{
  setselectedTags([...selectedTags, tag])
}
  }

  const deleteAnImageObject = async() => {
    setInterval(() => {
      setImageAsset((prevAsset) => ({ ...prevAsset,
        progress:0,
        uri:null,
        
      }));
    }, 2000);
   const deleteRef =ref(storage, ImageAsset.uri)
   deleteObject(deleteRef).then(()=>{
    toast.success("Image removed");
      

   })
  };

  const pushToCloud=async() =>{
 const timestamp =serverTimestamp()
 const id =`${Date.now()}`
 const _doc ={
  _id: id,
  title:formData.title,
  imageURL:ImageAsset.uri,
  tags:selectedTags,
  name:templates && templates.length > 0 ? `Template${templates.length+1}`:"Template1",
  timestamp:timestamp,
  
 }

 await setDoc(doc(db, "templates", id), _doc).then(()=>{
  setFormData((prevData)=> ({...prevData, title:"" , imageURL :"" }))
  setImageAsset((prevAsset)=>({...prevAsset, uri:null}))
  setselectedTags([])
  templatesRefetch()
  toast.success("Data pushed to the cloud")
 }).catch(error =>{
  toast.error(`Error : ${error.message}`)
 })

  }

  const removeTemplate =async (template)=>{
    const deleteRef=ref(storage, template?.imageURL)
    await deleteObject(deleteRef).then(async()=>{
await deleteDoc(doc(db, "templates", template?._id)).then(()=>{
  toast.success("Template removed from the cloud")
  templatesRefetch()
}).catch(err =>{
  toast.error(`Error : ${err.message}`)
})
    })
  }
  


useEffect(()=>{
if(!isLoading && !adminIds.includes(user?.uid)){
  navigate("/", {replace:true})
}

},[user, isLoading ])


  return (
    <div className='w-full px-4 lg:px-10 2xl:px-32 py-4 grid grid-cols-1 lg:grid-cols-12'>
      {/* Left Container */}
      <div className='col-span-12 lg:col-span-4 2xl:cols-span-3 flex flex-1 items-center justify-start flex-col gap-4 px-2'>
        <div className='w-full'>
          <p className='text-lg text-txtPrimary'>Create a new Template</p>
        </div>

        {/* TEMPLATE ID SECTION */}
        <div className='w-full flex items-center justify-end'> 
          <p className='text-base text-txtLight uppercase font-semibold'>tempId:</p>
          <p className='text-sm text-txtDark capitalize font-bold'>
            {templates && templates.length > 0 ? `Template${templates.length+1}`:"Template1"}
          </p>
        </div>

        {/* TEMPLATE Title SECTION */}
        <input className='w-full px-4 py-3 rounded-md bg-transparent border bg-gray-200 text-lg text-txtPrimary focus:text-txtDark focus:shadow-md outline-none'
          type='text'
          name='title'
          placeholder='Template Title'
          value={formData.title}
          onChange={handleInputChange}
        />

        {/* File Uploader Section */}
        <div className='w-full bg-gray-100 backdrop-blur-md h-[420px] lg:h-[620px] 2xl:h-[740px] rounded-md border-2 border-dotted border-gray-400 cursor-pointer flex items-center justify-center '>
          {ImageAsset.isImageLoading ? (
            <div className='flex flex-col items-center justify-center gap-4'>
              <PuffLoader color='#498FCD' size={40} />
              <p>{ImageAsset?.progress.toFixed(2)}% </p>
            </div>
          ) : (
            !ImageAsset?.uri ? (
              <React.Fragment> 
              <label className='w-full cursor-pointer h-full '>
                <div className='flex flex-col items-center justify-center h-full w-full'>
                  <div className='flex items-center justify-center cursor-pointer flex-col gap-4'>
                    <FaUpload className='text-2xl' />
                    <p className='text-lg text-txtLight'>Click to Upload</p>
                  </div>
                </div>
                <input type='file' className='w-0 h-0' accept='.jpeg,.jpg,.png' onChange={handleFileSelect} />
              </label>
              </React.Fragment>

            ) : (
              <React.Fragment> 
                <div className='relative w-full h-full overflow-hidden rounded-md'>
                  <img src={ImageAsset?.uri} className='w-full h-full object-cover' loading='lazy' alt='' />
             
             {/* delete action */}
             <div className='absolute top-4 right-4 w-8 h-8 rounded-md 
             items-center flex justify-center bg-red-500 cursor-pointer'>

<FaTrash className="text-sm text-white"  onClick={deleteAnImageObject}/>
             </div>
                </div>
              </React.Fragment>
                
            )
          )}
        </div>
         {/* tags */}
         <div className='w-full flex items-center flex-wrap mt-1  gap-3'>
{initialTags.map((tag,i)=>(
  <div key={i} className={`border bg-gray-300 px-2 py-1
     rounded-md cursor-pointer ${
      selectedTags.includes(tag)? "bg-blue-500 text-white":""}`} onClick={()=>handleSelectedTags(tag)}>
    <p className="text-xs">{tag} </p>
  </div>
))}
         </div>

           {/* button action */}

<button type='button' className='w-full bg-blue-700
 text-white rounded-md py-3 ' onClick={pushToCloud}>
save
</button>
      </div>

      {/* Right Container */}
      <div className='col-span-12 lg:col-span-8 2xl:cols-span-9 px-2 w-full flex-1 py-4 '>
        {templatesIsLoading ? (<React.Fragment>
          <div className='w-full h-full flex items-center justify-center'>
            <PuffLoader color='#498FCD' size={40} />

          </div>
        </React.Fragment>):(
          <React.Fragment>
          {templates && templates.length > 0 ? (
            <React.Fragment>
    <div className='w-full h-full grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-4'>
    {templates?.map((template)=>{
     return( <div key={template._id} className='w-full p-2 bg-slate-100 shadow-md h-[500px] rounded-md overflow-hidden relative '>
      <img src={template?.imageURL} alt='' className='w-full   h-full object-cover' />
      
{/* delete action */}
<div className='absolute top-4 right-4 w-8 h-8 rounded-md 
             items-center flex justify-center bg-red-500 cursor-pointer'>

<FaTrash className="text-sm text-white"  onClick={()=>removeTemplate(template)}/>
             </div>

       </div>)
    })}
    </div>
             </React.Fragment>):(
              <React.Fragment> 
                <div className='w-full h-full flex items-center justify-center'>
            <PuffLoader color='#498FCD' size={40} />
            <p className='text-xl tracking-wider capitalize text-txtPrimary'>
              No data
            </p>

          </div>
                 </React.Fragment>)}
          </React.Fragment>)}
      </div>
    </div>
  );
};

export default CreateTemplate;
