import { arrayRemove, arrayUnion, collection, doc,onSnapshot, orderBy, query, setDoc, updateDoc } from "firebase/firestore";
import { auth,db } from "../config/firebase.config";
import { toast } from "react-toastify";


export const getUserDetail = () => {
return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((userCred)=>{
        if(userCred){
            const userData = userCred.providerData[0];
            
            

           const unsubscribe =onSnapshot(doc(db, "users",userData?.uid),
           (_doc)=>{
if(_doc.exists()){
    resolve(_doc.data())
}else{
    setDoc(doc(db, "users",userData?.uid), userData).then(() =>{
        resolve(userData)
    })
}

           })
       return unsubscribe    
    }else {
        reject(new Error("User is not authenticated"))
    }
     unsubscribe()
})
    
})

}

export const getTemplates = () => {
    return new Promise((resolve, reject) => {
      const templateQuery = query(
        collection(db, "templates"),
        orderBy("timestamp", "asc")
      );
  
      const unsubscribe = onSnapshot(templateQuery, (querySnap) => {
        const templates = querySnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        resolve(templates);
      });
      return unsubscribe;
    });
  };
  

  export const saveToCollections = async (user, data) => {
    const docRef = doc(db, "users", user?.uid);
    const templateRef = doc(db, "templates", data?._id);

    if (!user?.collections?.includes(data?._id)) {
        // Adding to collections
        await updateDoc(docRef, {
            collections: arrayUnion(data?._id)
        });
        
       // // Unlocking the template for the user
      //  await updateDoc(templateRef, {
        //    isunlocked: arrayUnion(user?.uid)
     //   });

        toast.success("Saved to collection");
    } else {
        // Removing from collections
        await updateDoc(docRef, {
            collections: arrayRemove(data?._id)
        });

      //  // Optionally remove user from isunlocked if needed
       // await updateDoc(templateRef, {
      //      isunlocked: arrayRemove(user?.uid)
       // });

        toast.success("Removed from collection");
    }
};

export const saveToFavorites = async (user, data) => {
    const templateRef = doc(db, "templates", data?._id);

    if (!data?.favorites?.includes(user?.uid)) {
        // Liking the template
        await updateDoc(templateRef, {
            favorites: arrayUnion(user?.uid)
        });

       // // Unlocking the template for the user
     //   await updateDoc(templateRef, {
          //  isunlocked: arrayUnion(user?.uid)
     //   });

        toast.success("Liked the template");
    } else {
        // Disliking the template
        await updateDoc(templateRef, {
            favorites: arrayRemove(user?.uid)
        });

        // Optionally remove user from isunlocked if needed
      //  await updateDoc(templateRef, {
           // isunlocked: arrayRemove(user?.uid)
      //  });

        toast.success("Disliked the template");
    }
};


 export const  getTemplateDetails = async(templateId)=>{
  return new Promise((resolve, reject)=>{
      const unsubscribe =onSnapshot(doc(db, "templates", templateId),(doc)=>{
        resolve(doc.data())
      })
      return unsubscribe
  })
     
 }

 export const getTemplateDetailEditByUser = (uid, id) => {
    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(
        doc(db, "users", uid, "resumes", id),
        (doc) => {
          resolve(doc.data());
        }
      );
  
      return unsubscribe;
    });
  };

 
  export const  getSavedResumes = (uid)=>{
    return new Promise((resolve, reject)=>{
        const templateQuery =query(
      collection(db, "users", uid, "resumes"),
    orderBy("timestamp", "asc")

        )

        const unsubscribe = onSnapshot(templateQuery, (querySnap)=>{

            const templates = querySnap.docs.map((doc)=>doc.data());

            resolve(templates);

        })
        return unsubscribe
     
        })
    
}


