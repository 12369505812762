import React from 'react';
import { useNavigate } from 'react-router-dom';

function PrivacyPolicy() {
  const navigate = useNavigate(); // Get the navigate function

  // Function to handle back navigation
  const handleBackClick = () => {
    navigate('/auth'); // Navigate to the login page
  };

  return (
    <div className="max-w-3xl mx-auto p-6">
      <h1 className="text-4xl font-bold text-center text-gray-800">Privacy Policy</h1>

      <p className="mt-4 text-lg text-gray-600">
        At YourWebsite, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your information.
      </p>

      <h2 className="mt-6 text-2xl font-semibold text-gray-800">1. Information We Collect</h2>
      <p className="mt-2 text-gray-600">
        We may collect personal information when you visit our website or interact with our services, including:
      </p>
      <ul className="mt-2 list-inside list-disc text-gray-600">
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number (optional)</li>
        <li>Message content</li>
      </ul>

      <h2 className="mt-6 text-2xl font-semibold text-gray-800">2. How We Use Your Information</h2>
      <p className="mt-2 text-gray-600">
        Your information may be used for the following purposes:
      </p>
      <ul className="mt-2 list-inside list-disc text-gray-600">
        <li>To respond to your inquiries</li>
        <li>To improve our website and services</li>
        <li>To send important updates, if applicable</li>
      </ul>

      <h2 className="mt-6 text-2xl font-semibold text-gray-800">3. Information Security</h2>
      <p className="mt-2 text-gray-600">
        We use a variety of security measures to ensure the protection of your personal information.
      </p>

      <h2 className="mt-6 text-2xl font-semibold text-gray-800">4. Sharing Your Information</h2>
      <p className="mt-2 text-gray-600">
        We will never sell or share your personal information with third parties without your consent, except as required by law.
      </p>

      <h2 className="mt-6 text-2xl font-semibold text-gray-800">5. Your Rights</h2>
      <p className="mt-2 text-gray-600">
        You have the right to access, update, or delete your personal information at any time. Please contact us if you would like to exercise any of these rights.
      </p>

      <h2 className="mt-6 text-2xl font-semibold text-gray-800">6. Changes to This Privacy Policy</h2>
      <p className="mt-2 text-gray-600">
        We may update our Privacy Policy from time to time. Any changes will be posted on this page with the revised date.
      </p>

      <h2 className="mt-6 text-2xl font-semibold text-gray-800">7. Contact Us</h2>
      <p className="mt-2 text-gray-600">
        If you have any questions or concerns regarding this Privacy Policy, please contact us at <strong>cvdzbuilder@gmail.com</strong>.
      </p>

      {/* Back Button */}
      <button
        onClick={handleBackClick}
        className="mt-6 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700"
      >
        Back to Login Page
      </button>
    </div>
  );
}

export default PrivacyPolicy;
