import React, { useEffect, useState } from 'react';
import useUser from '../hooks/useUser';
import { AnimatePresence } from 'framer-motion';
import { MainSpinner, TemplateDesignPin } from '../components';
import useTemplates from '../hooks/useTemplates';
import { useNavigate } from 'react-router-dom';
import { NoData } from '../assets';
import { useQuery } from 'react-query';
import { getSavedResumes } from '../api';

function UserProfile() {
  const { data: user } = useUser();
  const [activeTab, setActiveTab] = useState("collections");
  const navigate = useNavigate();

  const { 
    data: templates,
    isError: temp_isError,
    isLoading: temp_isLoading,
  } = useTemplates();

  const { data: savedResumes } = useQuery(["savedResumes"], () => getSavedResumes(user?.uid));

  // Loading state
  if (temp_isLoading || !user) {
    return <MainSpinner />;
  }

  const hasUserCollections = Array.isArray(user?.collections) && user.collections.length > 0;
  const hasSavedResumes = Array.isArray(savedResumes) && savedResumes.length > 0;

  return (
    <div className='w-full flex flex-col items-center justify-start py-12'>
      <div className='w-full h-72 bg-blue-50'>
        <img  
          src='https://i.ibb.co/3vj5KHc/Free-Neutral-Gold-Beige-Modern-Luxury-Watercolor-Desktop-Background.jpg'
          className='w-full h-full object-cover'
          alt=''
        />

        <div className='flex items-center justify-center flex-col gap-4'>
          <img 
            src={user?.photoURL || "https://lifeimprovementsteps.com/wp-content/uploads/2023/07/become-a-better-person-1024x684-1.jpg"}
            className='w-24 h-24 object-cover rounded-full border-white -mt-8 shadow-md'
            alt=''
            referrerPolicy='no-referrer'
            loading='lazy'
          />
          <p className='text-2xl text-black'>{user?.displayName}</p>
        </div>

        {/* Tabs */}
        <div className='flex items-center justify-center mt-12'>
          <div onClick={() => setActiveTab("collections")} className={`px-4 py-2 rounded-md flex items-center justify-center gap-2 group cursor-pointer`}>
            <p className={`text-base capitalize text-black group-hover:text-blue-600 px-4 py-1 rounded-full 
              ${activeTab === 'collections' && "bg-white shadow-md text-blue-600"}`}>
              collections
            </p>
          </div>
        </div>

        {/* Tab contents */}
        <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-2 px-4 py-6'>
          <AnimatePresence>
            {activeTab === "collections" && (
              <React.Fragment>
                {hasUserCollections ? (
                  <RenderATemplate templates={templates?.filter((temp) => user.collections.includes(temp?._id))} />
                ) : (
                  <NoDataComponent />
                )}
              </React.Fragment>
            )}

            {activeTab === "resumes" && (
              <React.Fragment>
                {hasSavedResumes ? (
                  <RenderATemplate templates={savedResumes} />
                ) : (
                  <NoDataComponent />
                )}
              </React.Fragment>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

const RenderATemplate = ({ templates }) => {
  return (
    <>
      {templates && templates.length > 0 && (
        <AnimatePresence>
          {templates.map((template) => (
            <TemplateDesignPin key={template?._id} data={template} />
          ))}
        </AnimatePresence>
      )}
    </>
  );
};

const NoDataComponent = () => (
  <div className='col-span-12 w-full flex flex-col items-center justify-center gap-3'>
    <img src={NoData} className='w-32 h-auto object-contain' alt='' />
    <p>no data</p>
  </div>
);

export default UserProfile;
